import React from "react";
import SessionRoutes from "./Views/Session/SessionRoutes";
import ApplicationRoutes from "./Views/Application/ApplicationRoutes";
import { Navigate } from "react-router-dom";

const routes = [
  ...ApplicationRoutes,
  ...SessionRoutes,
  {
    path: "/",
    element: <Navigate to="/property/Prop_22757ff66a13463783f8d1c34e98e4b1" />,
  },
  { path: "403", element: <div>Permission Denied</div> },
  { path: "*", element: <div>Not Found</div> },
];

export default routes;

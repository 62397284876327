import React, { useState, useEffect } from "react";
import {
  IconButton,
  Stack,
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAllFiltersAction } from "../actions";
import { useLoading } from "src/Hooks/useLoading";
import useSession from "src/Hooks/useSession";

function FilterDialog() {
  const theme = useTheme();
  const { setLoading } = useLoading();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { selected_filters, show_filter_dialog, dispatch } = useSession();
  const { data: allFiltersData, isLoading } = useAllFiltersAction();
  const [allFilters, setAllFilters] = useState(null);
  const [selectedDietryFilters, setSelectedDietryFilters] = useState(
    selected_filters?.dietary_ids || []
  );
  const [selectedAllergenFilters, setSelectedAllergenFilters] = useState(
    selected_filters?.allergen_ids || []
  );

  const handleCloseDialog = () => {
    dispatch({
      type: "CLOSE_FILTER_DIALOG",
    });
  };
  const handleFilters = (action) => {
    if (action === "reset") {
      dispatch({
        type: "SET_SELECTED_FILTERS",
        payload: {
          selected_filters: { allergen_ids: "", dietary_ids: "" },
        },
      });
      setSelectedAllergenFilters([]);
      setSelectedDietryFilters([]);
    } else {
      dispatch({
        type: "SET_SELECTED_FILTERS",
        payload: {
          selected_filters: {
            allergen_ids: selectedAllergenFilters?.length
              ? selectedAllergenFilters
              : "",
            dietary_ids: selectedDietryFilters?.length
              ? selectedDietryFilters
              : "",
          },
        },
      });
    }
    handleCloseDialog();
  };

  useEffect(() => {
    if (allFiltersData?.data?.data) {
      setAllFilters(allFiltersData?.data?.data?.filter_map);
    }
  }, [allFiltersData]);

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  return (
    <>
      {allFilters && (
        <Dialog
          maxWidth="sm"
          open={show_filter_dialog}
          onClose={handleCloseDialog}
        >
          <DialogContent>
            <Box textAlign="center">
              <Stack direction="row" justifyContent="space-between">
                <Typography width="100%" my={{ xs: 1, md: 2 }} variant="h5">
                  Select your dietary recommendations?
                </Typography>
                <IconButton onClick={handleCloseDialog} color="black">
                  <CancelIcon></CancelIcon>
                </IconButton>
              </Stack>
              {allFilters?.Dietary?.map((i) => {
                let isSelected = selectedDietryFilters?.includes(
                  i?.sub_category_id
                );
                return (
                  <Button
                    key={i?.sub_category_id}
                    sx={{ mr: 1, mb: 1 }}
                    variant={isSelected ? "contained" : "outlined"}
                    color="black"
                    startIcon={
                      <img
                        style={{ padding: "2px" }}
                        height={isMobile ? "14px" : "24px"}
                        src={
                          isSelected
                            ? `/assets/icons/${i?.sub_category_label}_white.svg`
                            : `/assets/icons/${i?.sub_category_label}_black.svg`
                        }
                        onError={(e) => {
                          e.target.onerror = null; // prevents looping
                          e.target.src = isSelected
                            ? "/assets/icons/default_white.svg"
                            : "/assets/icons/default_black.svg";
                        }}
                        alt="i"
                      />
                    }
                    size="small"
                    onClick={() => {
                      let sel = selectedDietryFilters;
                      isSelected
                        ? (sel = sel?.filter((j) => j !== i?.sub_category_id))
                        : (sel = [...sel, i?.sub_category_id]);
                      setSelectedDietryFilters(sel);
                    }}
                  >
                    {i?.sub_category_label}
                  </Button>
                );
              })}

              <Typography my={{ xs: 1, md: 2 }} variant="h5">
                Select your allergen
              </Typography>
              {allFilters?.Allergen?.map((i) => {
                let isSelected = selectedAllergenFilters?.includes(
                  i?.sub_category_id
                );
                return (
                  <Button
                    key={i?.sub_category_id}
                    sx={{ mr: 1, mb: 1 }}
                    variant={isSelected ? "contained" : "outlined"}
                    color="black"
                    startIcon={
                      <img
                        style={{ padding: "2px" }}
                        height={isMobile ? "14px" : "24px"}
                        src={
                          isSelected
                            ? `/assets/icons/${i?.sub_category_label}_white.svg`
                            : `/assets/icons/${i?.sub_category_label}_black.svg`
                        }
                        alt="i"
                        onError={(e) => {
                          e.target.onerror = null; // prevents looping
                          e.target.src = isSelected
                            ? "/assets/icons/default_white.svg"
                            : "/assets/icons/default_black.svg";
                        }}
                      />
                    }
                    size="small"
                    onClick={() => {
                      let sel = selectedAllergenFilters;
                      isSelected
                        ? (sel = sel?.filter((j) => j !== i?.sub_category_id))
                        : (sel = [...sel, i?.sub_category_id]);
                      setSelectedAllergenFilters(sel);
                    }}
                  >
                    {i?.sub_category_label}
                  </Button>
                );
              })}
              <Stack mt={4} spacing={2} direction="row">
                <Button
                  onClick={() => handleFilters("reset")}
                  fullWidth
                  size="large"
                  variant="outlined"
                  disabled={
                    !(
                      selected_filters?.dietary_ids?.length +
                      selected_filters?.allergen_ids?.length
                    )
                  }
                >
                  Reset
                </Button>
                <Button
                  onClick={() => handleFilters("apply")}
                  fullWidth
                  size="large"
                  disabled={
                    !(
                      selectedAllergenFilters?.length +
                      selectedDietryFilters?.length
                    )
                  }
                >
                  Apply New Filter
                </Button>
              </Stack>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default FilterDialog;

import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  Typography,
  IconButton,
  Button,
  Divider,
  FormLabel,
} from "@mui/material";
import GoogleMapPicker from "src/Components/GoogleMapPicker";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { fromLatLng, setKey } from "react-geocode";
import { settings } from "src/config";
import { useSnackbar } from "notistack";
import { useLoading } from "src/Hooks/useLoading";
import useSession from "src/Hooks/useSession";
import CancelIcon from "@mui/icons-material/Cancel";
import { isDeliveryInRange } from "../actions";
import { useTheme } from "@mui/material";

function AddDeliveryAddress() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const {
    delivery_address,
    dispatch,
    show_delivery_dialog,
    sessionDetails: { restaurant },
  } = useSession();
  const [deliveryAddress, setDeliveryAddress] = useState(delivery_address);
  const [deliveryError, setDeliveryError] = useState(false);
  const setAddressFromLatLng = (lat_Lng) => {
    if (lat_Lng) {
      setLoading(true);
      setKey( settings.GOOGLE_API_KEY);
      fromLatLng(lat_Lng?.lat, lat_Lng?.lng)
        .then(({ results }) => {
          let address_com = results?.filter(
            (res) => !res?.types?.includes("plus_code")
          );
          setDeliveryAddress({
            ...lat_Lng,
            address:
              address_com[0]?.formatted_address ||
              results[0]?.formatted_address ||
              "",
          });

          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const handleCurrentLocation = () => {
    if ("geolocation" in navigator) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLoading(false);
          setDeliveryError(false);
          setAddressFromLatLng({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
        },
        function (error) {
          setLoading(false);
          enqueueSnackbar(
            error?.message || "Unable to retrieve user location.",
            {
              variant: "error",
            }
          );
        }
      );
    } else {
      enqueueSnackbar("Unable to retrieve user location.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      {show_delivery_dialog?.open && (
        <Dialog
          maxWidth="md"
          open={show_delivery_dialog?.open}
          onClose={(e, reason) => {
            if (reason === "backdropClick") {
              return;
            }
            dispatch({
              type: "CLOSE_DELIVERY_DIALOG",
            });
          }}
        >
          <DialogTitle>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" alignItems="center">
                <LocationOnIcon fontSize="large" />
                <Box>
                  <Typography variant="h4">Delivery details</Typography>
                  <Typography>
                    Enter details to check delivery services in your locality
                  </Typography>
                </Box>
              </Stack>
              <IconButton
                onClick={() =>
                  dispatch({
                    type: "CLOSE_DELIVERY_DIALOG",
                  })
                }
                color="black"
              >
                <CancelIcon></CancelIcon>
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <>
              <Divider />
              <GoogleMapPicker
                latLng={
                  Object.keys(deliveryAddress)?.length
                    ? {
                        lat: deliveryAddress?.lat,
                        lng: deliveryAddress?.lng,
                      }
                    : null
                }
                handleChange={(value) => {
                  setDeliveryError(false);
                  if (value?.address) {
                    setDeliveryAddress(value);
                  } else {
                    setAddressFromLatLng(value);
                  }
                }}
                inputProps={{
                  placeholder: "Search for an address",
                  fullWidth: true,
                }}
              />
              {deliveryAddress?.lat && (
                <Typography
                  display="flex"
                  alignItems="center"
                  component="div"
                  pt={1}
                  color={!deliveryAddress?.lat && "error"}
                >
                  <LocationOnIcon />
                  {deliveryAddress?.address}
                </Typography>
              )}

              <Button
                sx={{ px: 0, my: 1 }}
                onClick={handleCurrentLocation}
                variant="text"
              >
                <MyLocationIcon /> Use my current location
              </Button>

              {deliveryError && (
                <Box>
                  <FormLabel
                    sx={{
                      color: theme.palette.error.main,
                    }}
                  >
                    We don't deliver to the given Address
                  </FormLabel>
                </Box>
              )}

              <Button
                disabled={!deliveryAddress?.lat}
                onClick={() => {
                  setLoading(true);
                  isDeliveryInRange({
                    property_id: restaurant?.property_id,
                    latitude: deliveryAddress?.lat,
                    longitude: deliveryAddress?.lng,
                  })
                    .then((res) => {
                      setLoading(false);
                      if (res?.data?.status) {
                        dispatch({
                          type: "SET_DELIVERY_ADDRESS",
                          payload: {
                            delivery_address: deliveryAddress,
                          },
                        });
                        show_delivery_dialog?.onLocationSelect &&
                          show_delivery_dialog?.onLocationSelect(
                            deliveryAddress
                          );
                        setDeliveryError(false);
                        dispatch({
                          type: "CLOSE_DELIVERY_DIALOG",
                        });
                      } else {
                        setDeliveryError(true);
                      }
                    })
                    .finally(() => {
                      setLoading(false);
                    });
                }}
                sx={{ float: "right", my: 1 }}
              >
                Confirm location
              </Button>
            </>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default AddDeliveryAddress;

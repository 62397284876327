import { useRoutes } from "react-router-dom";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import routes from "./routes";
import { SessionProvider } from "./Contexts/SessionContext";
import { LoadingProvider } from "./Contexts/LoadingContext";
import { SnackbarProvider, closeSnackbar } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PullToRefresh from "react-simple-pull-to-refresh";
import ThemeProvider from "./Providers/ThemeProvider";
import CloseIcon from "@mui/icons-material/Close";
import AddDeliveryAddress from "./Views/Application/Components/AddDeliveryAddress";
import FilterDialog from "./Views/Application/Components/FilterDialog";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => {
  const content = useRoutes(routes);
  const isMobile = useMediaQuery("@media (max-width:899.95px)");

  return (
    <>
      <SessionProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <LoadingProvider>
              <SnackbarProvider
                action={(key) => (
                  <Box position="absolute" top="15%" right={0}>
                    <IconButton onClick={() => closeSnackbar(key)}>
                      <CloseIcon color="white" />
                    </IconButton>
                  </Box>
                )}
                autoHideDuration={1200}
                anchorOrigin={{
                  vertical: isMobile ? "top" : "bottom",
                  horizontal: isMobile ? "right" : "left",
                }}
                maxSnack={1}
              >
                <PullToRefresh
                  isPullable={isMobile}
                  onRefresh={async () => window.location.reload(false)}
                  pullDownThreshold={90}
                  resistance={2}
                >
                  <AddDeliveryAddress />
                  <FilterDialog />
                  {content}
                </PullToRefresh>
              </SnackbarProvider>
            </LoadingProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </SessionProvider>
    </>
  );
};

export default App;

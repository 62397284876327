// GoogleMapPicker.js
import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { settings } from "src/config";
import { Box, TextField, useMediaQuery } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DiningIcon from "@mui/icons-material/Dining";
import useSession from "src/Hooks/useSession";
import { useTheme } from "@emotion/react";
import { Search } from "@mui/icons-material";

const Marker = ({ color, Icon }) => (
  <Box sx={{ translate: "-20px -40px" }}>
    <Icon
      sx={{ p: 0, width: "32px", height: "32px" }}
      color={color || "primary"}
    />
  </Box>
);

const GoogleMapPicker = ({
  handleChange,
  latLng = null,
  inputProps = {},
  address,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const inputRef = useRef();
  const {
    sessionDetails: { restaurant },
  } = useSession();

  const restaurantLocation = {
    lat: restaurant?.latitude || 51.505,
    lng: restaurant?.longitude || -0.09,
  };

  const handleMapClick = (event) => {
    const newLocation = {
      lat: event.lat,
      lng: event.lng,
    };
    setSelectedLocation(newLocation);
    handleChange({
      lat: event.lat,
      lng: event.lng,
      address: event.address,
    });
  };

  useEffect(() => {
    latLng && setSelectedLocation(latLng);
  }, [latLng, address]);

  const handleApiLoaded = (props) => {
    if (props?.maps) {
      inputRef.current = new props.maps.places.Autocomplete(inputRef?.current);
      inputRef.current.addListener("place_changed", (event) => {
        let place = inputRef.current.getPlace();
        handleMapClick({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          address: place.formatted_address,
        });
      });
    }
  };

  return (
    <Box
      sx={{
        height: "40vh",
        minWidth: isMobile ? "280px" : "800px",
        width: "100%",
        mt: "12px",
        mb: "32px",
      }}
    >
      <TextField
        inputRef={inputRef}
        variant="standard"
        InputProps={{
          startAdornment: <Search />,
        }}
        {...inputProps}
      ></TextField>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: settings?.GOOGLE_API_KEY,
          libraries: ["places"].join(","),
        }}
        defaultCenter={restaurantLocation} // Initial position
        defaultZoom={15}
        center={latLng || restaurantLocation}
        onClick={handleMapClick}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
      >
        {selectedLocation && (
          <Marker
            color="error"
            lat={selectedLocation.lat}
            lng={selectedLocation.lng}
            Icon={LocationOnIcon}
          />
        )}
        {restaurantLocation && (
          <Marker
            Icon={DiningIcon}
            lat={restaurantLocation.lat}
            lng={restaurantLocation.lng}
          />
        )}
      </GoogleMapReact>
    </Box>
  );
};

export default GoogleMapPicker;

import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid, Stack } from "@mui/material";

export default function MenuSkelton({ showMenu = true }) {
  return (
    <Stack spacing={{ xs: 0, md: 2 }} direction="row">
      {showMenu && (
        <Stack sx={{ display: { xs: "none", md: "flex" } }} gap={2} width="20%">
          {[1, 2, 3, 4, 5, 6, 7]?.map((i) => (
            <Skeleton key={i} variant="rectangular" height="12vh" />
          ))}
        </Stack>
      )}
      <Stack spacing={2} width={{ xs: "100%", md: showMenu ? "80%" : "100%" }}>
        <Skeleton variant="rectangular" height="15vh" />
        <Grid container height="80vh" width={"calc(100% - 8px)"} spacing={1}>
          {[1, 2, 3, 4, 5, 6, 7, 8]?.map((i) => (
            <Grid key={i} item xs={6} md={3}>
              <Skeleton
                variant="rounded"
                sx={{ minHeight: "250px" }}
                height="100%"
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
}
